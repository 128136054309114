import React from 'react'
import propTypes from 'prop-types'
import { inputWrapper, inputLabel, inputComponent } from './styles.module.scss'

const Checkbox = props => {
  const required = props.required ? { required: 'required' } : {}
  const disabled = props.disabled ? { disabled: true } : {}
  const autoFocus = props.autoFocus ? { autoFocus: true } : {}
  const { className, ...origProps } = props

  return (
    <React.Fragment>
      <div className={inputWrapper}>
        <div className="checkbox-container">
          <input
            className={`${inputComponent} ${className || ''}`}
            type={'checkbox'}
            onChange={props.onChange}
            checked={props.checked}
            name={props.name}
            {...(props.id ? { id: props.id } : {})}
            {...required}
            {...disabled}
            {...origProps}
          />
        </div>
        <label className={`${inputLabel} ${props.required ? 'required' : ''}`} htmlFor={props.id}>
          {props.label} {props.required && !props.small ? <span className="required-label">Required</span> : ''}
        </label>
      </div>
    </React.Fragment>
  )
}

Checkbox.propTypes = {
  required: propTypes.bool,
  label: propTypes.string,
  className: propTypes.string,
  id: propTypes.string,
  name: propTypes.string,
  disabled: propTypes.bool,
  small: propTypes.bool,
  checked: propTypes.bool,
}

export default Checkbox
