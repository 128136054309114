// extracted by mini-css-extract-plugin
export const extensionUninstallContainer = "extension-uninstall-module__extensionUninstallContainer--g4kHj";
export const headline = "headline";
export const body = "body";
export const logo = "logo";
export const innerWrapper = "inner-wrapper";
export const column = "column";
export const message = "message";
export const survey = "survey";
export const messageContainer = "extension-uninstall-module__messageContainer--JHUXY";
export const avatar = "avatar";
export const thx = "thx";