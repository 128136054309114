import React, { useState } from 'react'
import axios from 'axios'
import { surveyContainer } from './index.module.scss'
import Checkbox from '../../checkbox'
import Input from '../../input'
import Button from '../../button'

export default function UninstallSurvey() {
  const [firstOptionOpen, setFirstOptionOpen] = useState(false)
  const [secondOptionOpen, setSecondOptionOpen] = useState(false)
  const [thirdOptionOpen, setThirdOptionOpen] = useState(false)
  const [fourthOptionOpen, setFourthOptionOpen] = useState(false)
  const [interacted, setInteracted] = useState(false)
  const [successMessage, setSuccessMessage] = useState()

  const [optionStillUsingBasaasDontLikeExtension, setoOptionStillUsingBasaasDontLikeExtension] = useState(false)
  const [optionDontLikeSidebar, setOptionDontLikeSidebar] = useState(false)
  const [optionDontLikeNewTab, setOptionDontLikeNewTab] = useState(false)

  const generatePayload = payload =>
    `<h3>Selected items in survey:</h3>
    <ul>
    ${Object.keys(payload)
      .map((key, index) => {
        return `<li><strong>${key}</strong>${payload[key] !== 'on' ? `<p>${payload[key]}</p>` : ''}</li>`
      })
      .join('')}</ul>`

  const handleSubmit = async event => {
    event.preventDefault()
    const WP_API_ENDPOINT = window.location.hostname.includes('basaasdev.de') && process.env.WORKPLACE_API_DEV ? process.env.WORKPLACE_API_DEV : process.env.WORKPLACE_API
    const data = new FormData(document.getElementById('uninstall-survey'))
    const value = Object.fromEntries(data.entries())
    const payloadString = generatePayload(value)
    try {
      await axios({
        method: 'post',
        url: `${WP_API_ENDPOINT}/contact-website/survey`,
        crossDomain: true,
        withCredentials: true,
        data: {
          payload: payloadString,
          initiator: 'EXTENSION_UNINSTALL',
        },
      }).then(res => {
        console.log('RES: ', res)
        setSuccessMessage('Thank you for your feedback!')
      })
    } catch (error) {
      console.log(error)
    }
  }

  const interactionHandler = () => {
    setInteracted(true)
  }

  return (
    <>
      {successMessage ? (
        <div className={surveyContainer}>
          <h1 className="survey-headline success">{successMessage}</h1>
        </div>
      ) : (
        <form id="uninstall-survey" className={surveyContainer}>
          <h1 className="survey-headline">Why did you uninstall the Basaas Extension?</h1>
          <span className="survey-description">Check all answers that apply.</span>

          <Checkbox
            id={'i-dont-use-basaas-anymore'}
            // required={true}
            name="I don't use Basaas anymore"
            label={`I don't use Basaas anymore`}
            onChange={interactionHandler}
          />

          <Checkbox
            id={'im-using-basaas-on-another-device-browser'}
            // required={true}
            name="I'm using Basaas on another device/browser"
            label={`I'm using Basaas on another device/browser`}
            onChange={interactionHandler}
          />

          <Checkbox
            id={'im-still-using-basaas-but-dont-need-extension'}
            // required={true}
            name="I'm still using Basaas but don't need the Extension"
            label={`I'm still using Basaas but don't need the Extension`}
            onChange={() => {
              setoOptionStillUsingBasaasDontLikeExtension(!optionStillUsingBasaasDontLikeExtension)
              interactionHandler()
            }}
          />

          {optionStillUsingBasaasDontLikeExtension && (
            <div className="option-collapse">
              <div className="collapse-wrapper">
                <h2 className="survey-subheadline">Would you mind telling us why?</h2>
                <Input type="textarea" name="im-still-using-basaas-but-dont-need-extension-other" placeholder="" />
              </div>
            </div>
          )}

          <Checkbox
            id={'i-dont-like-the-sidebar'}
            // required={true}
            name="I don't like the Sidebar"
            label={`I don't like the Sidebar`}
            onChange={() => {
              setOptionDontLikeSidebar(!optionDontLikeSidebar)
              interactionHandler()
            }}
          />
          {optionDontLikeSidebar && (
            <div className="info-wrapper">
              <span className="info-headline">Did you know that you can deactivate the sidebar?</span>

              <ol className="info-list">
                <li className="info-list-item">Open Extensions Option</li>
                <li>Scroll down to “Sidebar settings” and choose to disable your sidebar</li>
              </ol>

              <a className="info-more-link" href="https://help.basaas.com/hc/en-us/articles/360020220637" target="_blank" rel="noreferrer">
                Learn more
              </a>
            </div>
          )}

          <Checkbox
            id={'it-isnt-working-properly'}
            // required={true}
            name="It isn't working properly"
            label={`It isn't working properly`}
            onChange={() => {
              setFirstOptionOpen(!firstOptionOpen)
              interactionHandler()
            }}
          />

          {firstOptionOpen && (
            <div className="option-collapse">
              <div className="collapse-wrapper">
                <h2 className="survey-subheadline">Can you describe the things that didn&apos;t work properly?</h2>

                <Checkbox
                  id={'it-doesnt-work-on-the-website-i-use'}
                  // required={true}
                  name="It doesn't work on the websites I use"
                  label={`It doesn't work on the websites I use`}
                />

                <Checkbox
                  id={'the-shortcut-causes-problems'}
                  // required={true}
                  name="The shortcut causes problems"
                  label={`The shortcut causes problems`}
                />

                <Checkbox
                  id={'the-sidebar-isnt-workking-as-i-expected'}
                  // required={true}
                  name="The sidebar isn't working as I expected"
                  label={`The sidebar isn't working as I expected`}
                />

                <Input type="textarea" name="not-working-properly-other" placeholder="Something else?" />
              </div>
            </div>
          )}

          <Checkbox
            id={'i-prefer-to-use-another-browser'}
            // required={true}
            name="I prefer to use another browser"
            label={`I prefer to use another browser`}
            onChange={() => {
              setSecondOptionOpen(!secondOptionOpen)
              interactionHandler()
            }}
          />

          {secondOptionOpen && (
            <div className="option-collapse">
              <div className="collapse-wrapper">
                <h2 className="survey-subheadline">Which browser do you use?</h2>

                <Checkbox
                  id={'other-browser-firefox'}
                  // required={true}
                  name="Firefox"
                  label={`Firefox`}
                />

                <Checkbox
                  id={'other-browser-safari'}
                  // required={true}
                  name="Safari"
                  label={`Safari`}
                />

                <Checkbox
                  id={'other-browser-edge'}
                  // required={true}
                  name="Microsoft Edge"
                  label={`Microsoft Edge`}
                />

                <Input type="textarea" name="other-browser" placeholder="Something else?" />
              </div>
            </div>
          )}

          <Checkbox
            id={'it-isnt-what-i-expected'}
            // required={true}
            name="It isn't what i expected"
            label={`It isn't what i expected`}
            onChange={() => {
              setThirdOptionOpen(!thirdOptionOpen)
              interactionHandler()
            }}
          />

          {thirdOptionOpen && (
            <div className="option-collapse">
              <div className="collapse-wrapper">
                <h2 className="survey-subheadline">What were your expectations for the extension?</h2>

                <Input type="textarea" name="expectation-for-extension" placeholder="What were your expectations?" />
              </div>
            </div>
          )}

          <Checkbox
            id={'it-doesnt-have-the-features-integrations-i-need'}
            // required={true}
            name="It doesn't have the features / integrations I need"
            label={`It doesn't have the features / integrations I need`}
            onChange={() => {
              setFourthOptionOpen(!fourthOptionOpen)
              interactionHandler()
            }}
          />

          {fourthOptionOpen && (
            <div className="option-collapse">
              <div className="collapse-wrapper">
                <h2 className="survey-subheadline">What features or integrations are you missing?</h2>
                <Input type="textarea" name="missing-features-or-integrations" placeholder="Which feature or integration do you miss?" />
              </div>
            </div>
          )}

          <Checkbox
            id={'i-dont-like-the-new-tab'}
            // required={true}
            name="I don't like the new tab"
            label={`I don't like the new tab`}
            onChange={() => {
              setOptionDontLikeNewTab(!optionDontLikeNewTab)
              interactionHandler()
            }}
          />

          {optionDontLikeNewTab && (
            <div className="info-wrapper">
              <span className="info-headline">Did you know that you can deactivate the new tab? Or use the new tab of other extensions?</span>

              <ol className="info-list">
                <li className="info-list-item">Open Extension Options and go to “Manage new tab settings”</li>
                <li>Make sure to disable “Enable My-Day in new tab “</li>
              </ol>

              <a className="info-more-link" href="https://help.basaas.com/hc/en-us/articles/4402329908113" target="_blank" rel="noreferrer">
                Learn more
              </a>
            </div>
          )}

          <Checkbox
            id={'im-concerned-about-my-data-privacy'}
            // required={true}
            name="I'm concerned about my data privacy"
            label={`I'm concerned about my data privacy`}
            onChange={interactionHandler}
          />

          <Input className="textfield-other" type="textarea" name="other-feedback" placeholder="Something else you want to tell us?" />

          <div className="action-container">
            <Button className="primary" onClick={e => handleSubmit(e)} disabled={!interacted}>
              Submit
            </Button>
          </div>
        </form>
      )}
    </>
  )
}
