// extracted by mini-css-extract-plugin
export const surveyContainer = "index-module__surveyContainer--3KSxI";
export const surveyHeadline = "survey-headline";
export const success = "success";
export const surveyDescription = "survey-description";
export const surveySubheadline = "survey-subheadline";
export const optionCollapse = "option-collapse";
export const textfieldOther = "textfield-other";
export const primary = "primary";
export const actionContainer = "action-container";
export const infoWrapper = "info-wrapper";
export const infoHeadline = "info-headline";
export const infoList = "info-list";
export const infoListItem = "info-list-item";
export const infoMoreLink = "info-more-link";